<svg
	fill="none"
	stroke="currentColor"
	viewBox="0 0 24 24"
	width="100%"
	height="100%"
	xmlns="http://www.w3.org/2000/svg"
	aria-hidden="true"
	stroke-width="2"
	stroke-linecap="round"
	stroke-linejoin="round"
>
	<path
		stroke-linecap="round"
		stroke-linejoin="round"
		d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
	/>
</svg>
